<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.contractsExpiring')"
    id="contractsExpiring"
  >
    <div id="outer-title">
      {{ $t("i18n.contractsExpiring") }}
    </div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
// import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "RemindupkeepContractRemind",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    // const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "number",
          label: "maintenanceContractNo",
          align: "center",
          search: true,
          type: "input",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            enterEditPage(row);
          },
        },
        {
          prop: "ownerShortname",
          label: "ownerNickname",
          align: "left",
          width: 350,
          search: true,
          type: "input",
        },
        {
          prop: "remainDays",
          label: "contractRemainingDays",
          align: "center",
        },
        {
          prop: "createdDate",
          label: "createDate",
          align: "center",
        },
        {
          prop: "elevatorNumber",
          label: "elevatorNo",
          rows: false,
          search: true,
          type: "input",
        },
        {
          prop: "renewS",
          label: "renewalStatus",
          align: "center",
          search: true,
          type: "select",
          data: [],
          props: { label: "code", value: "value" },
          uploadUrl: "renew_status",
        },
        {
          prop: "upkeepType",
          label: "contractType",
          rows: false,
          search: true,
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "upkeep_type",
        },
        {
          prop: "status",
          label: "contractStatus",
          align: "center",
        },
        {
          prop: "remainElevator",
          label: "numberOfElevator",
          align: "center",
        },
        {
          prop: "endDate",
          label: "expiryDate",
          align: "center",
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [],
      },
      total: 0,
      loading: true,
      editData: {},
    });

    const init = async (d) => {
      let params = JSON.parse(JSON.stringify(d));
      state.loading = true;
      params.renewStatus = params.renewS;
      delete params.renewS;
      const { data } = await proxy.$api.system.getRemindersContract(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const enterEditPage = (row) => {
      let page = row.upkeepType == 10 ? "Viewupdate" : "Viewcou";
      router.push({
        path: "/upkeepContract/upkeepContract" + page,
        query: { id: row.id },
      });
    };

    return {
      ...toRefs(state),
      enterEditPage,
      init,
    };
  },
};
</script>
